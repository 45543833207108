

#stage {
    position: relative;
    @include breakpoint(large) {
    min-height: 768px;
    height: 100vh;
    }
    .brand {
        margin: 60px auto;
        height: 30px;
        width: 310px;
        @include breakpoint(medium) {
            margin: 60px 0 0;
        }
    }
    .btn {
        position: relative;
        z-index: 999;
        width: 100px;
        height: 100px;
        margin-top: 30px;
        margin-bottom: 100px;
        float: right;
        @include breakpoint(medium) {
        width: 200px;
        height: 200px;
        }
    }
}

.content__section-cover {
    background-size: cover;
    background-position: center;
    padding: 0 0 30px;
    @include breakpoint(large) {
        min-height: 768px;
        height: 100vh;
    }
    .orbit {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -999;
        &-container {

        }
        &-slide {
            height: 100%;
            width: 100%;
            padding-bottom: 30px;
            background-size: cover;
            background-position: center;
        }
    }
}


.content__section {
    &--content {
        background: rgba($white, 0.7);
        @include breakpoint(large) {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
    &--headline {
        color: $primary-color;
        text-align: center;
        padding: 1.5rem 0;
        h1 {
            line-height: 1;
            margin-bottom: 1rem;
        }
        h2 {
            line-height: 1;
            margin-bottom: 2rem;
            span {
            }
        }
        sub, sup {
          font-size: 1.2rem;
        }
        a.contact {
            text-transform: uppercase;
            border-top: 3px solid $primary-color;
            padding: 0.2rem 7rem;
            font-weight: $global-weight-bold;
            font-size: 1.4rem;
        }
    }

    &--form {
        padding: 1.5rem 0;
        form {
            label {
                text-transform: uppercase;
            }
        }
        .address {
            font-family: $header-font-family;
            font-size: rem-calc(27);
            color: $primary-color;
            text-align: center;
            margin-top: 30px;
            p {
                line-height: 1.2;
            }
            @include breakpoint(large) {
                text-align: left;
            }
        }
    }
    &--footer {
        font-family: $header-font-family;
        font-size: 1.5rem;
        color: $primary-color;
        font-style: italic;
        padding: 1rem 0;
        background: $secondary-color;
        a {
            color: $primary-color;
        }
        h3, h4 , h5 {
            font-style: italic;
            font-weight: normal;
            margin: 0;
            line-height: 1.2;
        }
        .cta {
            margin-top: 30px;
            text-align: center;
            @include breakpoint(large) {
                margin-top: 40px;
                text-align: left;
            }
        }
    }

}
